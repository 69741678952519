import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiXcode,
  SiAndroidstudio,
  SiPostgresql,
  SiGitkraken,
  SiVisualstudio,
  SiIntellijidea,
  SiFirebase,
  SiDocker
} from "react-icons/si";

function Toolstack() {
  return (
    <>
      <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <Col xs={4} md={2} className="tech-icons">
          <SiVisualstudiocode />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <SiXcode />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <SiIntellijidea />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <SiPostgresql />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <SiAndroidstudio />
        </Col>
      </Row>
      <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <Col xs={4} md={2} className="tech-icons">
          <SiPostman />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <SiGitkraken />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <SiVisualstudio />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <SiFirebase />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <SiDocker />
        </Col>
      </Row>
    </>
  );
}

export default Toolstack;
