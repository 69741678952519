import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import to50 from "../../Assets/Projects/1to50.png";
import bitsOfCode from "../../Assets/Projects/resmigazeteios2.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've individually developed recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Resmi Gazete IOS & Android"
              description="Official Gazette of the Republic of Turkey IOS and Android Applications.It was published in app stores from 2013 until the end of 2019. Applications reached more than 300,000 unique users. It received more than 20000 votes and comments and achieved an average rating of 4.5 out of 5."
            />
          </Col>
          <Col md={4} className="project-card">

            <ProjectCard
              imgPath={to50}
              isBlog={false}
              title="1to50 Race"
              description="An ios game with the theme of melting the mixed and randomly given numbers from 1 to 50 on a 5x5 board as quickly as possible."
              // ghLink=""
              demoLink="https://apps.apple.com/us/app/1-to-50-race/id1447751286"
            />
          </Col>

         
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
