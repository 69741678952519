import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/profil2.jpeg";
import {
  AiFillGithub,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  let date = new Date();
  let year = date.getFullYear();
  const experience = year-2014;
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I am excited to introduce myself as a full stack developer with expertise in
              <br />
              <i>
                <b className="purple"> Java, C#, ReactJS, NodeJS, and Python. </b>
              </i>
              <br />With over {experience} years of experience in the software development industry.
              <br />
              <br />
              As a full stack developer, I have experience working in both
              <i>
                <b className="purple"> &nbsp;front-end </b> and
                <b className="purple">
                  &nbsp; back-end development.
                </b>
              </i>
              <br />
              <br />
              My ability to work seamlessly across the entire technology stack allows me to deliver end-to-end solutions that are efficient and effective &nbsp;
              <br />
              <br />
              I also have a personal interest in development of
              <i>
                <b className="purple">&nbsp;iOS and Android Mobile Applications.</b>
              </i>
              <br />
              <br />
              In addition, I have a special curiosity about
              <i>
                <b className="purple">&nbsp;Machine Learning</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
              <img src={myImg} className="img-fluid" alt="avatar" />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/slymnozdmrc"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/suleyman-ozdemirci-219b0149/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/slymnozdmrc"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
