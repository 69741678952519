import React from "react";
import Card from "react-bootstrap/Card";
import { TbLego } from "react-icons/tb";
import { IoLogoGameControllerB ,IoIosAirplane} from "react-icons/io";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Süleyman Özdemirci </span>
            from <span className="purple"> Istanbul, Turkey.</span>
            <br />I am a full stack software developer.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <IoLogoGameControllerB /> Playing Games
            </li>
            <li className="about-activity">
              <TbLego /> Building Lego
            </li>
            <li className="about-activity">
              <IoIosAirplane /> Travelling
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
